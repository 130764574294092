<template>
    <Container title="API and Document" :current-name="$options.name">
        <h1>{{$options.name}}</h1>
    </Container>

</template>

<script>
import Container from '../components/Container'
export default {
    name:'API-Document',
    components:{
        Container
    }
}
</script>